import type { Options } from "@contentful/rich-text-react-renderer";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import type {
  Block,
  Document,
  Inline,
  TopLevelBlock,
} from "@contentful/rich-text-types";
import { INLINES } from "@contentful/rich-text-types";
import { BLOCKS } from "@contentful/rich-text-types";
import type { HeroBackground } from "~/components/Hero";
import { Hero } from "~/components/Hero";
import type {
  CONTENT_TYPE,
  IAccordionFields,
  IFeatureFields,
  IHeroFields,
  SpecificLocale,
  SpecificLocaleFields,
  Asset,
  IVideoFields,
  ICaseStudiesFields,
  IArticleLinksFields,
} from "~/@types/generated/contentful";
import { ContentfulAsset } from "./components/ContentfulAsset";
import { ArticleFeature } from "~/components/ArticleFeature";
import type { ImgHTMLAttributes } from "react";
import { AccordionSection } from "~/components/AccordionSection";
import { Link } from "~/components/Link";
import { Video } from "~/components/Video";
import { CaseStudy } from "~/components/CaseStudy";
import { ArticleLink } from "~/components/ArticleLink";
import { assertUnreachable } from "~/utils";
import type { SupportedLang } from "~/i18n-config";

export function getImageProps(
  asset: SpecificLocale<Asset>
): ImgHTMLAttributes<HTMLImageElement>;
export function getImageProps(
  asset: Asset,
  options: { locale: SupportedLang["code"] }
): ImgHTMLAttributes<HTMLImageElement>;
export function getImageProps(
  asset: Asset | SpecificLocale<Asset>,
  options?: { locale: SupportedLang["code"] }
): unknown {
  /**
   * If you're looking to add translations, make sure to add the locale to
   * Contentful before you try to use it (see the README).
   * If you add a "supportedLang" before setting up contentful (and then regenerating types),
   * you'll see type errors in this file.
   */
  const locale = options?.locale;

  const file = locale
    ? (asset as Asset).fields.file[locale]
    : (asset as SpecificLocale<Asset>).fields.file;
  if (file && !file.details.image) {
    throw new Error(
      "tried to get image props from an asset that's not an image"
    );
  }

  return {
    width: file?.details.image!.width,
    height: file?.details.image!.height,
    src: file?.url + "?fm=webp",
    srcSet:
      file &&
      getSrcSet({
        width: file.details.image!.width,
        url: file.url,
      }),
    alt: locale
      ? (asset as Asset).fields.description[locale]
      : (asset as SpecificLocale<Asset>).fields.description,
  };
}

export function getSrcSet({
  width,
  url,
  increment = 300,
}: {
  width: number;
  url: string;
  increment?: number;
}) {
  // Generate a srcset entry at each increment
  let srcSetItems = [];
  for (let i = 1; i < width / increment; i++) {
    // Calculate width for this iteration (for example: 500, 1000, 1500, etc.)
    const width = i * increment;
    srcSetItems.push(`${url}?w=${width}&q=80&fm=webp ${width}w`);
  }
  return srcSetItems.join(",");
}

function getButtonProps(
  fields: SpecificLocaleFields<IHeroFields | IFeatureFields>
):
  | {
      label: string;
      to: string;
    }
  | undefined {
  if (fields.buttonLabel && fields.buttonUrl) {
    return {
      label: fields.buttonLabel,
      to: fields.buttonUrl,
    };
  }
}

function getHeroBackground(
  fields: SpecificLocaleFields<IHeroFields>
): HeroBackground | undefined {
  if (fields.backgroundImage) {
    return {
      imageProps: getImageProps(fields.backgroundImage),
    };
  } else if (fields.backgroundColor) {
    return {
      color: fields.backgroundColor,
    };
  }
}

/**
 * This function returns components generated from a Contentful Rich Text entry.
 */
export const richTextOptions: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <Link rel="noreferrer" href={node.data.uri}>
          {children}
        </Link>
      );
    },
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1 className="my-4 text-4xl font-bold tracking-tight sm:text-5xl md:text-6xl lg:text-7xl">
        {children}
      </h1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2 className="my-4 text-4xl tracking-tight sm:text-5xl md:text-6xl lg:text-7xl">
        {children}
      </h2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 className="mt-12 text-3xl tracking-tight md:text-4xl">{children}</h3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h4 className="my-2 text-xl font-bold tracking-tight">{children}</h4>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <h5 className="my-2 text-lg font-bold tracking-tight">{children}</h5>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <h6 className="my-2 text-base font-bold tracking-tight">{children}</h6>
    ),
    [BLOCKS.HR]: (/* assume no children */) => <hr className="my-6" />,
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className="list-disc pl-7">{children}</ul>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol className="list-decimal pl-7">{children}</ol>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => <div>{children}</div>,
    [BLOCKS.QUOTE]: (node, children) => (
      <blockquote className="border-l-8 pl-3">{children}</blockquote>
    ),
    [BLOCKS.TABLE]: (node, children) => (
      <table className="mb-5 w-full table-auto border text-sm">
        <tbody>{children}</tbody>
      </table>
    ),
    [BLOCKS.TABLE_HEADER_CELL]: (node, children) => (
      <th className="border-b px-4 pt-4 text-left font-medium">{children}</th>
    ),
    [BLOCKS.TABLE_CELL]: (node, children) => (
      <td className="border-b px-4 pt-4">{children}</td>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      const asset = node.data.target as SpecificLocale<Asset>;
      return <ContentfulAsset asset={asset} />;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
      const contentType: CONTENT_TYPE = node.data.target.sys.contentType.sys.id;
      if (contentType === "hero") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IHeroFields>;
        return (
          <section id={fields.id}>
            <Hero
              content={documentToReactComponents(
                fields.content,
                richTextOptions
              )}
              position={fields.position}
              background={getHeroBackground(fields)}
              textColor={fields.textColor}
              button={getButtonProps(fields)}
              firstSection={fields.firstSection}
            />
          </section>
        );
      } else if (contentType === "feature") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IFeatureFields>;
        return (
          <section id={fields.id}>
            <ArticleFeature
              category={fields.category}
              title={fields.title}
              description={fields.body}
              imageProps={fields.image && getImageProps(fields.image)}
              contentPosition={fields.contentPosition}
              backgroundColor={fields.backgroundColor}
              button={getButtonProps(fields)}
            />
          </section>
        );
      } else if (contentType === "accordion") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IAccordionFields>;

        return (
          <section id={fields.id} className="bg-secondary">
            <div className="contained-width flex flex-row">
              <div>
                {fields.accordionItems.map((item) => (
                  <AccordionSection
                    key={item.sys.id}
                    title={item.fields.title}
                    content={item.fields.body}
                  />
                ))}
              </div>
            </div>
          </section>
        );
      } else if (contentType === "video") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IVideoFields>;

        return (
          <>
            <Video
              id={fields.id}
              title={fields.title}
              source={fields.video.fields.file.url}
            />
          </>
        );
      } else if (contentType === "caseStudies") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<ICaseStudiesFields>;

        return (
          <section className="mb-12 px-[30px] lg:px-[60px]" id={fields.id}>
            <div className="py-16 text-center">
              <h2>{fields.title}</h2>
              <div className="mt-4 text-lg sm:text-xl xl:text-2xl">
                {fields.description}
              </div>
            </div>
            <div className="flex flex-col items-center justify-center gap-6 lg:flex-row">
              {fields.entries.map((caseStudy) => (
                <CaseStudy
                  key={caseStudy.sys.id}
                  title={caseStudy.fields.title}
                  description={caseStudy.fields.description}
                  imageUrl={caseStudy.fields.image.fields.file.url}
                  url={caseStudy.fields.url}
                />
              ))}
            </div>
          </section>
        );
      } else if (contentType === "articleLinks") {
        const fields = node.data.target
          .fields as SpecificLocaleFields<IArticleLinksFields>;

        return (
          <section className="mb-12 px-[30px] lg:px-[60px]">
            <div className="py-16 text-center">
              <h2>{fields.title}</h2>
              <p className="mt-6">{fields.description}</p>
            </div>
            <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-4">
              {fields.articles.map((article: any) => (
                <ArticleLink
                  key={article.sys.id}
                  title={article.fields.title}
                  author={article.fields.author}
                  category={article.fields.category}
                  imageUrl={article.fields.image.fields.file.url}
                  url={article.fields.url}
                />
              ))}
            </div>
          </section>
        );
      } else if (
        contentType === "navContainer" ||
        contentType === "navItem" ||
        contentType === "accordionItem" ||
        contentType === "migration" ||
        contentType === "page" ||
        contentType === "alertBanner" ||
        contentType === "caseStudy" ||
        contentType === "articleLink"
      ) {
        // This is intentionally empty to capture content types that we know exist and are confident will never be embedded in a rich text document
        // It's here to make the assertUnreachable(contentType) below help us catch new content types and either add to this list, or handle appropriately above
      } else {
        assertUnreachable(contentType);
      }
    },
  },
};

type SectionType = "full" | "inset";

export interface Section {
  type: SectionType;
  nodes: TopLevelBlock[];
}

// This is used to separate parts of a rich text field into sections so we can wrap them differently (inset on the page vs. full width, etc.)
export function getSections(document: Document): Section[] {
  const sectionTypes: { [key in BLOCKS]?: SectionType } = {
    [BLOCKS.EMBEDDED_ENTRY]: "full",
  };
  const defaultSectionType: SectionType = "inset";
  return document.content.reduce<Section[]>((sections, node) => {
    // Figure out what section type we're aiming for for this node
    const targetSectionType = sectionTypes[node.nodeType] || defaultSectionType;
    // Get the last element of our sections array for the current section
    const section = sections.at(-1);
    if (section && section.type === targetSectionType) {
      // We have a section and it matches our target type, so add our node
      section.nodes.push(node);
    } else {
      // We either don't have a section at all, or it doesn't match our target type, so create a new one with our node in it
      sections.push({
        type: targetSectionType,
        nodes: [node],
      });
    }
    return sections;
  }, []);
}

export function createDocumentFromSection(section: Section): Document {
  return { content: section.nodes, nodeType: BLOCKS.DOCUMENT, data: {} };
}

// This is used to generate a 155 character description (for the meta description tag) from a Contentful node tree
export function getDescriptionFromNode(
  node: Block | Inline,
  description: string = ""
): string {
  for (const childNode of node.content) {
    if (description.length >= 150) {
      // We need at least 5 free characters (out of 155) to add another word, so if we're at 150 or greater, we're done
      break;
    }
    if (childNode.nodeType === "text") {
      // This is text, so add it one word at a time up to our length limit
      // The trim() is to remove carriage returns
      const words = childNode.value.trim().split(/\s+/);
      for (const word of words) {
        // We're tracking towards a total description length of 155
        // We need 5 characters for ` ${word} ...` (two surrounding spaces and three periods for the ellipsis)
        // That means the max word length we're looking for is the difference between 150 and our current description length
        const maxWordLength = 150 - description.length;
        if (word.length <= maxWordLength) {
          // The word fits
          if (description !== "") {
            // If there's something in the description already, then prepend a space (there's a word before this word)
            description += " ";
          }
          description += word;
        } else {
          // This word doesn't fit, so add an ellipsis then break
          description += " ...";
          break;
        }
      }
    } else {
      // Keep going deeper in the tree to find some text to add to the description
      description = getDescriptionFromNode(childNode, description);
    }
  }
  return description;
}
