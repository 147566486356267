import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useLoaderData } from "@remix-run/react";
import type {
  HeadersFunction,
  LoaderFunction,
} from "@remix-run/server-runtime";
import invariant from "tiny-invariant";
import type { SpecificLocale, IPage } from "../@types/generated/contentful";
import { createDocumentFromSection, getSections, richTextOptions } from ".";
import { getPageBySlug } from "./index.server";
import { i18n } from "../i18n.server";

export const headers: HeadersFunction = () => {
  return {
    "Cache-Control": "public, max-age=300",
  };
};

export interface ContentfulPageLoaderData {
  page: SpecificLocale<IPage>;
}

export function getContentfulPageLoader(slug?: string) {
  const contentfulPageLoader: LoaderFunction = async ({
    params,
    request,
  }): Promise<ContentfulPageLoaderData> => {
    const url = new URL(request.url);
    const slugToQuery = slug || params.slug;
    invariant(
      slugToQuery,
      "expected slug to be passed as an argument or params.slug to be defined"
    );
    const locale = await i18n.getLocale(request);
    const page = await getPageBySlug(slugToQuery, {
      locale,
      preview: url.searchParams.get("preview") === "1",
    });
    return {
      page,
    };
  };
  return contentfulPageLoader;
}

export function ContentfulPageComponent() {
  const { page } = useLoaderData<ContentfulPageLoaderData>();
  return (
    <div id="intro">
      {page.fields.body &&
        getSections(page.fields.body).map((section, index) => {
          if (section.type === "inset") {
            return (
              <div key={index} className="mx-4 md:mx-auto md:w-3/4">
                {documentToReactComponents(
                  createDocumentFromSection(section),
                  richTextOptions
                )}
              </div>
            );
          } else {
            return documentToReactComponents(
              createDocumentFromSection(section),
              richTextOptions
            );
          }
        })}
    </div>
  );
}
