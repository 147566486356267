import type { ImgHTMLAttributes } from "react";
import { Button } from "./Button";
import { motion } from "framer-motion";

interface ImageBackground {
  imageProps: ImgHTMLAttributes<HTMLImageElement>;
}

interface ColorBackground {
  color: string;
}

export type HeroBackground = ImageBackground | ColorBackground;

export type HeroProps = {
  className?: string;
  background?: HeroBackground;
  textColor?: string;
  content?: React.ReactNode;
  position: "left" | "center" | "offset";
  button?: {
    label: string;
    to: string;
    className?: string;
  };
  firstSection?: boolean;
};

function getBackgroundStyle(background?: HeroBackground) {
  if (background && "color" in background) {
    return background.color;
  }
}

export const Hero = ({
  className = "",
  background,
  textColor = "primary",
  content,
  position,
  button,
  firstSection,
}: HeroProps) => {
  return (
    <div
      className={`${className} ${textColor} bg-${getBackgroundStyle(
        background
      )}`}
    >
      <div className="contained-width">
        <div
          className={`${
            position === "left" ? "md:mr-[25%] lg:mr-[22.5%] xl:mr-[42.5%]" : ""
          } py-12 px-[30px] lg:py-36`}
        >
          {background && "imageProps" in background && (
            // eslint-disable-next-line jsx-a11y/alt-text
            <img
              {...background.imageProps}
              className="h-full w-full object-cover"
            />
          )}
          <motion.div
            className={`flex items-center gap-16 ${
              position === "center" ? "justify-center" : "justify-start"
            }`}
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            <div
              className={`
            ${
              position === "center"
                ? "text-md flex flex-col gap-4 text-center md:flex-row md:gap-12"
                : ""
            }
            ${position === "offset" ? "lg:ml-[40%]" : ""}
          `}
            >
              {firstSection ? (
                <div
                  className={`my-4  text-${textColor} ${
                    position === "center"
                      ? "text-md"
                      : "text-4xl sm:text-5xl md:text-6xl lg:text-7xl"
                  }`}
                >
                  {content}
                </div>
              ) : (
                <h2
                  className={`my-4  text-${textColor} ${
                    position === "center"
                      ? "text-md"
                      : "text-4xl sm:text-5xl md:text-6xl lg:text-7xl"
                  }`}
                >
                  {content}
                </h2>
              )}
              <div
                className={`flex items-center ${
                  position === "center" ? "justify-center" : ""
                }`}
              >
                {button && (
                  <Button
                    className={position !== "center" ? "mt-4" : "mt-0"}
                    variant={background ? "secondary" : "primary"}
                    href={button.to}
                    as="a"
                  >
                    {button.label}
                  </Button>
                )}
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};
