import type { LoaderFunction } from "@remix-run/server-runtime";
import * as routeUtils from "~/contentful/route-utils";

export const headers = routeUtils.headers;

export const loader: LoaderFunction =
  routeUtils.getContentfulPageLoader("main");

/**
 * Export the main page from Contentful.
 */
const ContentfulContent = routeUtils.ContentfulPageComponent;

export default function Main() {
  return <ContentfulContent />;
}
