import { motion } from "framer-motion";
import { Button } from "./Button";

export type CaseStudyProps = {
  className?: string;
  title: string;
  description: string;
  imageUrl: string;
  url: string;
};

export const CaseStudy = ({
  className = "",
  title,
  description,
  imageUrl,
  url,
}: CaseStudyProps) => {
  return (
    <motion.article
      className={`mx-6 mb-8 flex max-w-[500px] flex-col items-center justify-between lg:mx-0 ${className}`}
      initial={{ y: 100, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{ once: true }}
      transition={{ delay: 0.2, duration: 0.6 }}
    >
      <img
        className="aspect-square max-h-[300px] max-w-[300px] rounded-lg bg-secondary"
        alt={`${title}: Case Study`}
        src={imageUrl + "?fm=webp&w=300&h=300"}
        loading="lazy"
        height={300}
        width={300}
      />

      <div className="flex flex-col justify-start text-center">
        <h3 className="mt-4">{title}</h3>
        <div className="text-lg">{description}</div>
      </div>
      <Button className="mt-6 uppercase" href={url} as="a">
        Read Article
      </Button>
    </motion.article>
  );
};
