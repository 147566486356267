import { Button } from "./Button";

export type ArticleLinkProps = {
  className?: string;
  title: string;
  category: string;
  author: string;
  imageUrl: string;
  url: string;
};

export const ArticleLink = ({
  className = "",
  title,
  category,
  author,
  imageUrl,
  url,
}: ArticleLinkProps) => {
  return (
    <article
      className={`mb-8 flex max-w-[500px] flex-col items-center justify-start ${className}`}
    >
      <img
        className="aspect-square max-w-[200px]"
        alt={`${title}`}
        src={imageUrl}
        loading="lazy"
      />
      <div className="text-center">
        <p className="my-4">
          {author} <span className="mx-1">•</span> {category}
        </p>
        <p>{title}</p>
        <Button className="mt-6" href={url} as="a">
          READ MORE
        </Button>
      </div>
    </article>
  );
};
