import type { ImgHTMLAttributes } from "react";
import { Button } from "./Button";
import { motion } from "framer-motion";

export type ArticleFeatureProps = {
  className?: string;
  title: string;
  description: string;
  imageProps?: ImgHTMLAttributes<HTMLImageElement>;
  contentPosition?: "left" | "right";
  category?: string;
  backgroundColor?: string;
  button?: {
    label: string;
    to: string;
  };
};

export const ArticleFeature = ({
  className = "",
  title,
  description,
  imageProps,
  contentPosition = "left",
  category = "",
  backgroundColor,
  button,
}: ArticleFeatureProps) => {
  return (
    <motion.div
      className={`mb-8 ${className} px-[30px] lg:px-[60px]`}
      style={{
        backgroundColor: backgroundColor ? backgroundColor : "transparent",
      }}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true, amount: 0.4 }}
      transition={{ delay: 0.1, duration: 0.8 }}
      whileHover={{ scale: 1.05 }}
    >
      <div
        className={`contained-width mx-4 mb-8 flex flex-wrap justify-center gap-x-10 py-4 md:mx-auto md:w-3/4 md:flex-nowrap lg:gap-x-20 lg:py-10 xl:gap-x-28`}
      >
        {imageProps && (
          // eslint-disable-next-line jsx-a11y/alt-text
          <img
            className={`mb-4 object-contain md:w-1/2 ${
              contentPosition === "left" && "md:order-2"
            }`}
            {...imageProps}
          />
        )}

        <div
          className={`flex flex-col justify-center ${
            imageProps ? "" : "md:w-3/4"
          }`}
        >
          <p>{category}</p>
          <h2 className="my-4 text-4xl sm:text-5xl xl:text-6xl">{title}</h2>
          <div className="my-4 mt-6 text-lg sm:text-xl md:mt-8 xl:text-2xl">
            {description}
          </div>
          {button && (
            <Button className="md:mt-2" to={button.to}>
              {button.label}
            </Button>
          )}
        </div>
      </div>
    </motion.div>
  );
};
