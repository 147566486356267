import { motion } from "framer-motion";

export type AccordionSectionProps = {
  title: string;
  content: string;
  className?: string;
};

export const AccordionSection = ({
  title,
  className,
}: AccordionSectionProps) => {
  return (
    <motion.article
      className={`my-16 flex w-full flex-col items-center px-[30px] text-white md:flex-row lg:px-[60px] ${className}`}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true, amount: 0.3 }}
      transition={{ delay: 0.1, duration: 0.8 }}
    >
      <div>
        <h3>⦿ {title}</h3>
      </div>
    </motion.article>
  );
};
