import { useState, useEffect } from "react";
import ReactPlayer from "react-player/file";
import { Button } from "./Button";
import { motion } from "framer-motion";

export type VideoProps = {
  title: string;
  id?: string;
  source: string;
  className?: string;
};

export const Video = ({ source, title, className, id }: VideoProps) => {
  const [playing, setPlaying] = useState(false);
  const [hasWindow, setHasWindow] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setHasWindow(true);
    }
  }, []);

  return (
    <motion.section
      className={`relative pt-[56.25%] ${className} z-10 cursor-pointer`}
      id={id}
      onClick={() => setPlaying(!playing)}
      initial={{ y: 100, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{ once: true }}
      transition={{ delay: 0.5, duration: 0.5 }}
    >
      {!playing && (
        <Button
          variant="secondary"
          className="absolute top-[50%] left-[50%] z-50 flex translate-x-[-50%] translate-y-[-50%] shadow-xl"
          icon={<PlayIcon />}
        >
          Watch Video
        </Button>
      )}
      {hasWindow && (
        <ReactPlayer
          className="absolute top-0 left-0"
          width="100%"
          height="100%"
          url={source}
          playing={playing}
          config={{
            attributes: {
              preload: "metadata",
              poster:
                "https://images.ctfassets.net/mfhcn2x3bqxh/7nFMij6QBeWaaEOOJVbN3W/c29adbd05d058cb553ac6ace8e01a2c0/cover.png?fm=webp",
            },
          }}
        />
      )}
    </motion.section>
  );
};

const PlayIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="mr-4 h-6 w-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z"
    />
  </svg>
);
